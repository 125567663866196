import { login, getListRouter } from '@/api/user/logo'
import { setToken, removeToken } from '@/utils/auth'

import router from '@/router'
const state = {
    passSafe:'N',
    token: localStorage.getItem('token_manage') ? localStorage.getItem('token_manage') : '', // token认证
    userName: localStorage.getItem('userName') ? localStorage.getItem('userName') : '',
    routerList: localStorage.getItem('routerList') ? localStorage.getItem('routerList') : '[]'
}
const mutations = {
    SET_PASSSAFE (state, val) {
        state.passSafe = val
    },
    SET_ROUTES(state, val) {
        state.routerList = JSON.stringify(val)
        localStorage.setItem('routerList', JSON.stringify(val))
    },
    SET_TOKEN(state, val) {
        state.token = val
        setToken(val)
        localStorage.setItem('token_manage', val)
    },
    DEL_TOKEN(state) {
        state.token = ''
        removeToken()
        localStorage.removeItem('token_manage')
        sessionStorage.removeItem('token_manage')
        localStorage.removeItem('perms')
        localStorage.removeItem('routerList')
    },
    SET_NAME(state, val) {
        state.userName = val
    },
    DEL_NAME(state) {
        state.userName = ''
        localStorage.removeItem('userName')
    }
}
const actions = {
    setpassSafe({ commit }, formdatas) {
        commit('SET_PASSSAFE',formdatas)
    },
    // user login
    _login({ commit }, formdatas) {
        return new Promise((resolve, reject) => {
            login(formdatas)
                .then(res => {
                    if (res.state === 'success') {
                        commit('SET_TOKEN', res.body)
                        localStorage.setItem('pptToken', res.body)
                    }
                    resolve(res)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    loginOut({ commit }) {
        commit('DEL_TOKEN')
        commit('DEL_NAME')
        router.push({
            path: '/login',
        })
    },
    tokenLose({ commit }) {
        commit('DEL_TOKEN')
        router.push({
            path: '/login',
            query: {
                tokenLose: 'true',
                redirect: router.currentRoute.fullPath
            }
        })
    },
    addRoutes({ commit }) {
        return new Promise((resolve, reject) => {
            getListRouter({
                    systemCode: 'cms',
                })
                .then(res => {
                    if (res.state === 'success') {
                        let resJson = res.body;
                        resJson.findIndex(item => {
                            if (item.path == '/home') {
                                item.meta = { affix: true }
                            }
                        })
                        commit('SET_ROUTES', resJson)
                    }
                    resolve(res)
                })
                .catch(error => {
                    reject(error)
                })
        })
    }
}
export default {
    namespaced: true,
    state,
    mutations,
    actions
}